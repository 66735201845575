import React, { Component } from "react";
import MroDictionaryViewer from "./MRODictionaryViewer";
import UploadMroDictionary from "./UploadMroDictionary";
import NounModifierTemplateList from "./NounModifierTemplateList";
import CreateNounModifierTemplate from "./CreateNounModifierTemplate";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import helper from "../../helpers/helpers";
import accessControlService from "../../services/accessControl.service";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./MRODictionaryNew.scss";

toast.configure();

class MRODictionary extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 0,
      accessControl: [],
      defaultActiveKey: "mroDictionaryViewer",
      canUserAccessUploadMRODictionary: false,
      canUserAccessMroDictionaryViewer: false,
      canUserAccessNounModifierTemplateList: false,
      canUserAccessCreateNounModifierTemplate: false,
      spinnerMessage: "Please wait while loading...",
      loading: false,
    };
  }

  toggle(tab, defaultActiveKey) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
      sessionStorage.setItem("activeTab", tab);
    }
  }

  componentDidMount() {
    const set_activeTab = sessionStorage.getItem("activeTab");
    this.fetchUserRoleAccess(set_activeTab);

    if (set_activeTab) {
      this.setState({
        activeTab: parseInt(set_activeTab),
        defaultActiveKey: "nounModifierTemplateList",
      });
    }
  }
  //#region User Access
  fetchUserRoleAccess(set_activeTab) {
    this.setState({
      spinnerMessage: "Please wait while loading...",
      loading: true,
    });

    accessControlService
      .ReadUserMenuAccessList(helper.getUser(), "MRO Dictionary")
      .then((response) => {
        this.setState(
          {
            accessControl: response.data,
          },
          () => {
            const canUserAccessMRODictionaryViewer =
              this.state.accessControl.find(
                (a) => a.PageName === "MRO Dictionary Viewer"
              );
            const canUserAccessUploadMRODictionary =
              this.state.accessControl.find(
                (a) => a.PageName === "Upload MRO Dictionary"
              );
            const canUserAccessNounModifierTemplateList =
              this.state.accessControl.find(
                (a) => a.PageName === "Noun-Modifier Template List"
              );
            const canUserAccessCreateNounModifierTemplate =
              this.state.accessControl.find(
                (a) => a.PageName === "Create Noun-Modifier Template"
              );

            let activeTab = this.state.activeTab;
            let defaultActiveKey = "";

            if (set_activeTab) {
              activeTab = set_activeTab;
              defaultActiveKey = "nounModifierTemplateList";
              this.setState({
                canUserAccessMRODictionaryViewer:
                  canUserAccessMRODictionaryViewer.canAccess,
                canUserAccessUploadMRODictionary:
                  canUserAccessUploadMRODictionary.canAccess,
                canUserAccessNounModifierTemplateList:
                  canUserAccessNounModifierTemplateList.canAccess,
                canUserAccessCreateNounModifierTemplate:
                  canUserAccessCreateNounModifierTemplate.canAccess,
                loading: false,
              });
            } else {
              if (canUserAccessMRODictionaryViewer.canAccess) {
                activeTab = 1;
                defaultActiveKey = "mroDictionaryViewer";
              } else if (canUserAccessUploadMRODictionary.canAccess) {
                activeTab = 2;
                defaultActiveKey = "uploadMRODictionary";
              } else if (canUserAccessNounModifierTemplateList.canAccess) {
                activeTab = 3;
                defaultActiveKey = "nounModifierTemplateList";
              } else if (canUserAccessCreateNounModifierTemplate.canAccess) {
                activeTab = 4;
                defaultActiveKey = "createNounModifierTemplate";
              }

              this.setState({
                activeTab: activeTab,
                defaultActiveKey: defaultActiveKey,
                canUserAccessMRODictionaryViewer:
                  canUserAccessMRODictionaryViewer.canAccess,
                canUserAccessUploadMRODictionary:
                  canUserAccessUploadMRODictionary.canAccess,
                canUserAccessNounModifierTemplateList:
                  canUserAccessNounModifierTemplateList.canAccess,
                canUserAccessCreateNounModifierTemplate:
                  canUserAccessCreateNounModifierTemplate.canAccess,
                loading: false,
              });
            }
          }
        );
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.Message, { autoClose: false });
      });
  }
  //#endregion

  render() {
    const { activeTab, defaultActiveKey, loading, spinnerMessage } = this.state;

    return activeTab === 0 ? (
      <LoadingOverlay
        active={true}
        className="custom-loader"
        spinner={
          <div className="spinner-background">
            <BarLoader
              css={helper.getcss()}
              color={"#38D643"}
              width={"350px"}
              height={"10px"}
              speedMultiplier={0.3}
            />
            <p style={{ color: "black", marginTop: "5px" }}>{spinnerMessage}</p>
          </div>
        }
      >
        <p style={{ height: "580px" }}></p>
      </LoadingOverlay>
    ) : (
      <div>
        <LoadingOverlay
          active={loading}
          className="custom-loader"
          spinner={
            <div className="spinner-background">
              <BarLoader
                css={helper.getcss()}
                color={"#38D643"}
                width={"350px"}
                height={"10px"}
                speedMultiplier={0.3}
              />
              <p style={{ color: "black", marginTop: "5px" }}>
                {spinnerMessage}
              </p>
            </div>
          }
        >
          <Tab.Container defaultActiveKey={defaultActiveKey}>
            <div className="row" style={{ marginRight: "15px" }}>
              <div className="col-md-10">
                <Nav variant="pills" className="mg-l-35 mg-b-0 mg-t-10">
                  {this.state.canUserAccessMRODictionaryViewer && (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="mroDictionaryViewer"
                        style={{ border: "1px solid #5E41FC" }}
                        onClick={() => {
                          this.toggle(1, "mroDictionaryViewer");
                        }}
                      >
                        MRO Dictionary Viewer
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {this.state.canUserAccessUploadMRODictionary && (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="uploadMRODictionary"
                        style={{ border: "1px solid #5E41FC" }}
                        onClick={() => {
                          this.toggle(2, "uploadMRODictionary");
                        }}
                      >
                        Upload MRO Dictionary
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {this.state.canUserAccessNounModifierTemplateList && (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="nounModifierTemplateList"
                        style={{ border: "1px solid #5E41FC" }}
                        onClick={() => {
                          this.toggle(3, "nounModifierTemplateList");
                        }}
                      >
                        Noun - Modifier Template List
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {this.state.canUserAccessCreateNounModifierTemplate && (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="createNounModifierTemplate"
                        style={{ border: "1px solid #5E41FC" }}
                        onClick={() => {
                          this.toggle(4, "createNounModifierTemplate");
                        }}
                      >
                        Create Noun - Modifier Template
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
              </div>
            </div>

            <Tab.Content>
              <Tab.Pane eventKey="mroDictionaryViewer">
                {activeTab === 1 && <MroDictionaryViewer />}
              </Tab.Pane>
              <Tab.Pane eventKey="uploadMRODictionary">
                {activeTab === 2 && <UploadMroDictionary />}
              </Tab.Pane>
              <Tab.Pane eventKey="nounModifierTemplateList">
                {activeTab === 3 && (
                  <NounModifierTemplateList {...this.props} />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="createNounModifierTemplate">
                {activeTab === 4 && <CreateNounModifierTemplate />}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </LoadingOverlay>
      </div>
    );
  }
}

export default MRODictionary;
